import React, { useRef } from 'react'
import clamp from 'lodash-es/clamp'
import { useSprings, animated } from 'react-spring'
import { useDrag } from 'react-use-gesture'

const pages = [
  "https://i.loli.net/2020/08/12/Yn1TS5e2z8Pp6ik.jpg",
  "https://i.loli.net/2020/08/12/mBKw4S8ARQ9NuVd.jpg",
  "https://i.loli.net/2020/08/12/gJ3lV1joZNYx9Sf.jpg",
  "https://i.loli.net/2020/08/12/tnESBD2X1q8OdHW.jpg",
  "https://i.loli.net/2020/08/12/avhd6xgiQmLDqEH.jpg",
  "https://i.loli.net/2020/08/12/WwMTnJOeLuSB4x5.jpg",
  "https://i.loli.net/2020/08/12/dp2MmPKXaWj4CZh.jpg",
  "https://i.loli.net/2020/08/12/On4pUlcFwyahebN.jpg",
  "https://i.loli.net/2020/08/12/lRFgiMAaqsSwtPd.jpg",
  "https://i.loli.net/2020/08/12/d4svCmZruJNywa8.jpg",
  "https://i.loli.net/2020/08/12/Cvu5oR36K9mAVFM.jpg",
  "https://i.loli.net/2020/08/12/32b9cjt74QVMlTW.jpg"
]

const Carousel = () => {
  const index = useRef(0)
  const [props, set] = useSprings(pages.length, i => ({
    x: i * 335,
    scale: 1,
    display: 'block'
  }))
  const bind = useDrag(({ down, movement: [mx], direction: [xDir], distance, cancel }) => {
    if (down && distance > 335 / 2){
      cancel((index.current = clamp(index.current + (xDir > 0 ? -1 : 1), 0, pages.length - 1)))
    }else{
      set(i => {
        if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
        const x = (i - index.current) * 335 + (down ? mx : 0)
        const scale = down ? 1 - distance / 335 / 2 : 1
        return { x, scale, display: 'block' }
      })
    }
  })
  return (
    <div className="carousel-container">
      {
        props.map(({ x, display, scale }, i) => (
          <animated.div {...bind()} key={i} style={{ display, x }}>
            <animated.div style={{ scale, backgroundImage: `url(${pages[i]})` }} />
          </animated.div>
        ))
      }
    </div>
  )
}

export default Carousel
