import React from "react"
import { Link, graphql } from "gatsby"
import moment from "moment";

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Carousel from "../components/carousel";

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <Carousel />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        const formatString = new Date().getFullYear() > new Date(node.frontmatter.date).getFullYear() ? 'dddd, MMMM Do YYYY' : 'dddd, MMMM Do'
        return (
          <article key={node.fields.slug} className="article-item">
            <header>
              <small>{moment(Number(node.frontmatter.date)).format(formatString)}</small>
              <h2>
                <Link to={node.fields.slug}>
                  {title}
                </Link>
              </h2>
            </header>
            {
              !!node.frontmatter.description && <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description,
                  }}
                />
              </section>
            }
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/blog/"}}
      sort: {fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            title
            description
          }
        }
      }
    }
  }
`
